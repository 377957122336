.assetAdder {
  display: flex;
  margin-top: auto;
}
.assetAdderCost {
  width: 110px;
  margin: 0px 5px;
}

.footer {
  background-color: white;
  margin: 5px;
  padding: 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 10px;
  border: 3px solid;
  border-color: white;
}

.footer > button {
  flex: 1;
  margin: 5px 5px;
}
.addButton {
  margin: 0px 5px;
}
.returnButton {
  max-width: 100px;
  margin: 0px 5px;
}

.demoSwitch {
  max-width: 80px;
}
/* Asset Selector */

.assetSelect {
  min-width: 200px;
  margin: 0px 5px;
}
.assetSelectDropdown {
  min-width: 300px !important;
}
.potfolioSelect {
  min-width: 200px;
  margin: 0px 5px;
}
.potfolioSelect > div {
  border-radius: 5px;
}

.porfolioSelectDropdown {
  min-width: 300px !important;
}

.messageContainer {
  display: flex;
  flex: 1;
}
.messageContainer > input {
  border-radius: 8px;
}
.sendNotificaitonButton {
  margin-left: 5px;
}
.Adjustment {
  min-width: 110px;
  max-width: 180px;
  margin: 0px 5px;
  border-radius: 10px;
}
.footer > h5 {
  margin: 0px 5px;
}
.typeSelect {
  min-width: 150px;
  margin: 0px 5px;
}
.typeSelectDropdown {
  min-width: 200px !important;
}
