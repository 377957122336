.reviewPageContainer {
  margin-bottom: 20px;
}
.position {
  color: white;
  text-align: center;
}
.rank {
  font-size: 15px;
  margin-bottom: 2px;
  font-weight: normal;
}
.header {
  font-weight: bold;
  text-align: center;
  background-image: linear-gradient(310deg, #2f3a9b 23%, #165d9b 73%);
  background-attachment: fixed;
  color: white;
  font-size: 16px;
}
.header > tr > th {
  padding: 5px 5px;
}

.balanceTable > tbody > tr > td {
  text-align: center;
  padding: 5px 12px;
  background-color: rgb(255, 255, 255);
}

.balanceTable > tbody > tr > td:first-child {
  font-weight: bold;
  text-align: left;
}
.timerColumn {
  display: flex;
  flex-direction: column;
}

.header > tr > th:first-child {
  border-radius: 8px 0px 0px 0px;
}

.header > tr > th:last-child {
  border-radius: 0px 8px 0px 0px;
}

.balanceTable > tbody > tr:last-child > td:last-child {
  border-radius: 0px 0px 8px 0px;
}

.balanceTable > tbody > tr:last-child > td:first-child {
  border-radius: 0px 0px 0px 8px;
}
