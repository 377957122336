body {
  background-color: lightgrey;
}
label {
  font-size: calc(0.7rem + 0.5vw);
}
h1 {
  font-size: calc(1rem + 1.1vw);
}
h2 {
  margin: 4px 0px;
  font-size: calc(0.9rem + 0.9vw);
}
h3 {
  margin: 4px 0px;
  font-size: calc(0.8rem + 0.6vw);
}
h4 {
  line-height: calc(1rem + 0.5vw);
  font-size: calc(1rem + 0.2vw);
}
h5 {
  /* used for small center bold titles */
  font-size: calc(0.6rem + 0.4vw);
  text-align: center;
  font-weight: bold;
}
sub {
  font-size: calc(0.4rem + 0.3vw);
  font-weight: normal;
}
strong {
  font-weight: bold;
}

#root {
  height: fit-content;
}

body {
  height: fit-content;
  background-image: linear-gradient(310deg, #2f367a 23%, #004e92 73%);
  background-attachment: fixed;
}

@media screen and (max-width: 1414px) and (min-width: 1250px) {
  body {
    zoom: 90% !important;
  }
}

@media screen and (max-width: 1250px) and (min-width: 1000px) {
  body {
    zoom: 85% !important;
  }
}

.componentHeader {
  display: flex;
  align-items: center;
  border-bottom: 2px solid rgb(105, 105, 105);
  background: rgb(0, 42, 98);
  margin: -5px -5px 5px -5px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  padding: 2px 6px;
}
.componentHeader > h3 {
  color: white;
}
.componentHeader > span {
  margin: 0px 4px 0px auto;
  transition: 0.5s;
  transform-origin: 50% 50%;
  color: white;
}
